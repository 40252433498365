import { MinusIcon } from "../../../../../components/icons/MibusIcon";
import { PlusIcon } from "../../../../../components/icons/PlusIcon";
import { OkinusOpensOffer } from "./OkinusOpensOffer";
import { RevolvingLineOpensOffer } from "./RevolvingLineOpensOffer";
import { CommonOpensOffer } from "./CommonOpensOffer";
import { ConcoraOpensOffer } from "./ConcoraOpensOffer";
import "./index.scss";

interface OfferItemProps {
  offer: any;
  openItem?: number | null;
  selectOption?: any;
  setOpenItem?: any;
  setSelectOption?: any;
  isWithdrawn?: boolean;
}

export enum LoanTypesEnum {
  LeaseToOwn = "Lease-to-own",
  RevolvingLine = "Revolving line",
}

export const OpensOfferItem = ({
  offer,
  openItem,
  selectOption,
  setOpenItem,
  setSelectOption,
}: OfferItemProps) => {
  return (
    <div className="OfferItem">
      <div className={`OfferItem__item`}>
        <div>{offer.name}</div>
        {openItem === offer.id ? (
          <div
            className="OfferItem__minus"
            onClick={() => {
              setOpenItem(null);
              setSelectOption(null);
            }}
          >
            <MinusIcon fill={"#1F44DD"} width="23" height="23" />
          </div>
        ) : (
          <div
            className="OfferItem__plus"
            onClick={() => {
              setOpenItem(offer.id);
              setSelectOption(null);
            }}
          >
            <PlusIcon fill={"white"} width="25" height="25" />
          </div>
        )}
      </div>

      <div
        className={`OfferItem__openItem ${
          openItem === offer.id ? "open" : "closed"
        }`}
      >
        {offer?.banks?.map((elem: any) => {
          return (
            <>
              <div className="OfferItem__head">
                <div className="OfferItem__title">
                  <img
                    src={elem.bank_logo}
                    className="OfferItem__logo"
                    alt="bank logo"
                  />
                  <span>{elem.bank_name}</span>
                  {offer.name === "Promotional" && (
                    <span className="OfferItem__offer_name">
                      {elem.loan_type}
                    </span>
                  )}
                </div>
              </div>
              <div className="OfferItem__line"></div>
              {elem.offers?.map((item: any, index: number) => {
                if (
                  offer.name === LoanTypesEnum.LeaseToOwn &&
                  elem.key === "okinus"
                ) {
                  return (
                    <OkinusOpensOffer
                      openItem={openItem}
                      offer={offer}
                      selectOption={selectOption}
                      item={item}
                      elem={elem}
                      index={index}
                      setSelectOption={setSelectOption}
                    />
                  );
                } else if (elem?.key === "genesis") {
                  return (
                    <ConcoraOpensOffer
                      openItem={openItem}
                      offer={offer}
                      selectOption={selectOption}
                      item={item}
                      elem={elem}
                      index={index}
                      setSelectOption={setSelectOption}
                    />
                  );
                } else if (offer.name === LoanTypesEnum.RevolvingLine) {
                  return (
                    <RevolvingLineOpensOffer
                      openItem={openItem}
                      offer={offer}
                      selectOption={selectOption}
                      item={item}
                      elem={elem}
                      index={index}
                      setSelectOption={setSelectOption}
                    />
                  );
                } else {
                  return (
                    <CommonOpensOffer
                      openItem={openItem}
                      offer={offer}
                      selectOption={selectOption}
                      item={item}
                      elem={elem}
                      index={index}
                      setSelectOption={setSelectOption}
                    />
                  );
                }
              })}
            </>
          );
        })}
      </div>
    </div>
  );
};
