import moment from "moment";

import { Button, Form, Tooltip } from "antd";
import { TrashIcon } from "../../../components/icons/TrashIcon";
import { OpensOfferItem } from "../Offers/components/OpensOffer/OpensOfferItem";
import { ApplicantI } from "../../../api/types/applicants";
import { ISelectOfferRes } from "../../../api/types/offer";
import { CustomSelect } from "../../../customFields/CustomSelect";
import { SelectedOffer } from "../Offers/components/SelectedOffer";

import useViewApplicationController from "./view-application.controller";

import { useEffect, useState } from "react";
import { Offer } from "../Offers/components/OnlyOffer/Offer";
import { Spinner } from "../../../components/Spinner/Spinner";
import { ConfirmationModal } from "../../../components/Modals/ConfirmationModal";
import { distributionTooltipLayout } from "../../Lenders/tooltips";
import info from "../../../assets/info-circle-blue.svg";
import check from "../../../assets/check-small-gray.svg";
import "./index.scss";
import { TitleRecepientInfo } from "../../../components/LayoutBlocks/TitleRecepientInfo";
import { CopyLink } from "../../../components/LayoutBlocks/CopyLink";
import {
  useCheckTransactionStatusMutation,
  useCloneApplicationMutation,
  useConfirmRefundMutation,
  useResendDocumentsMutation,
  useStartRefundMutation,
} from "../../../api/applicants";
import {
  ADMIN,
  PARTNER,
  SUPER_ADMIN,
  MAIN_ACCOUNT,
} from "../../../helpers/variables";
import { useAppSelector } from "../../../hooks";
import { useDispatch } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import { setOpenNotificationModal } from "../../../reducers/tabs/tabsSlice";
import { useParams } from "react-router-dom";
import {
  successMessage,
  errorMessage,
} from "../../../components/Messages/messages";
import ResendDocuments from "../../../assets/_Button base.svg";
import { ProfileSkeleton } from "../../../components/Skeletons/ProfileSkeleton";
import { CustomInput } from "../../../customFields/CustomInput";

interface ViewApplicationProps {
  applicationData?: ApplicantI;
  renewApplication: any;
  trigger: boolean;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

export const ViewApplication = ({
  applicationData,
  renewApplication,
  setTrigger,
  isLoading,
}: ViewApplicationProps) => {
  const {
    selectedValue,
    navigate,
    options,
    handleChange,
    getStatus,
    handleSendOffersByType,
    offersLabel,
    isAvailableOffers,
    selectedOfferData,
    isSelectedOfferLoading,
    isOfferSelected,
    setIsOfferSelected,
    isError,
    error,
    contextHolder,
    messageApi,
    openItem,
    selectOption,
    setOpenItem,
    setSelectOption,
    refreshStatus,
    offersChecked,
    ISSENDBTN,
    isChecking,
  } = useViewApplicationController({ applicationData, renewApplication });
  const { id } = useParams();
  const [
    checkTransactionStatus,
    { isLoading: checkStatusLoading, isSuccess: transactionCheckSuccess },
  ] = useCheckTransactionStatusMutation();
  const dispatch = useDispatch();
  const setOpenNotification = (payload: boolean): AnyAction =>
    dispatch(setOpenNotificationModal(payload));
  const user = useAppSelector((state) => state.profile);
  const [openRerun, setOpenRerun] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [cloneApplication, { data: cloneData }] = useCloneApplicationMutation();
  const [resendDocuments, { isSuccess, isLoading: isLoadingResendDocuments }] =
    useResendDocumentsMutation();
  const [form] = Form.useForm<any>();
  const [
    startRefund,
    {
      isSuccess: isSuccessRefund,
      isError: isErrorRefund,
      isLoading: isLoadingRefund,
    },
  ] = useStartRefundMutation();
  const [
    confirmRefund,
    {
      isSuccess: isSuccessConfirmRefund,
      isError: isErrorConfirmRefund,
      isLoading: isLoadingConfirmRefund,
    },
  ] = useConfirmRefundMutation();

  const ApprovedConcora =
    applicationData?.data?.application_status === "Approved" &&
    applicationData?.data?.bank?.key === "genesis";

  const RefundedConcora =
    applicationData?.data?.application_status === "Refunded" &&
    applicationData?.data?.bank?.key === "genesis";

  const IsApproveBtn =
    applicationData?.data?.additional_application_status ===
    "Awaiting Approval";

  const isApprovedBtn =
    applicationData?.data?.application_status === "Approved" &&
    !applicationData?.data?.additional_application_status;

  useEffect(() => {
    if (isSuccessConfirmRefund) {
      setTrigger((prev: boolean) => !prev);
    }
    if (isErrorConfirmRefund) {
    }
  }, [isSuccessConfirmRefund, isErrorConfirmRefund]);

  useEffect(() => {
    if (isSuccessRefund) {
      setOpenRefund(false);
      successMessage(
        messageApi,
        "Your refund request has been successfully sent"
      );
      setTrigger((prev: boolean) => !prev);
    }
    if (isErrorRefund) {
      setOpenRefund(false);

      errorMessage(
        messageApi,
        "Your refund request has been declined by the bank"
      );
    }
  }, [isSuccessRefund, isErrorRefund]);

  useEffect(() => {
    if (!openRefund) {
      form.setFieldsValue({ refund_amount: null });
    }
  }, [openRefund]);

  //CHANGE availableCredit TO TRANS.AMOUNT
  const Transaction_Amount =
    applicationData?.data?.offers?.[0]?.banks?.[0]?.offers?.[0]?.offer_value;

  const validateRefundAmount = async (_: any, value: any) => {
    if (!Transaction_Amount) return;
    if (value && parseFloat(value.replace(/,/g, "")) > +Transaction_Amount) {
      return Promise.reject(
        new Error("Refund cannot exceed the transaction amount")
      );
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (isSuccess) {
      successMessage(messageApi, "Documents has been successfully resend!");
    }
  }, [isSuccess]);

  const cloneApp = () => {
    cloneApplication(applicationData?.data.id);
    setOpenRerun(false);
  };

  useEffect(() => {
    transactionCheckSuccess &&
      successMessage(messageApi, "Transaction status checked");
  }, [transactionCheckSuccess]);

  useEffect(() => {
    if (cloneData?.data?.id) {
      navigate(`/applications/edit/${cloneData?.data?.id}`);
      setOpenNotification(true);
    }
  }, [cloneData]);

  useEffect(() => {
    if (isError) {
    }
  }, [isError, error]);

  const currencyMaskFinancingAmount = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let value = e.target.value;
    value = value.replace(/^0/, "");
    value = value.replace(/[^\d.]/g, "");
    value = value.replace(/\.{2,}/g, ".");
    value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    e.target.value = value;
    const number = value.replace(/,/g, "");

    const parts = number?.split(".");
    if (parts.length === 2 && parts[1].length > 2) {
      e.target.value = `${parts[0]}.${parts[1].substring(0, 2)}`;
    }

    return e.target.value;
  };

  if (isOfferSelected) {
    const goToList = () => {
      setIsOfferSelected(false);
      navigate(`/applications/edit/${applicationData?.data.id}`);
    };

    return (
      <SelectedOffer
        selectedOfferData={selectedOfferData as ISelectOfferRes}
        isSelectedOfferLoading={isSelectedOfferLoading}
        isViewApplication
        goTo={goToList}
        buttonText="View Application"
        contextHolder={contextHolder}
      />
    );
  }

  const resendDocumentFunc = () => {
    const id = applicationData?.data?.id;
    if (!id) return;
    resendDocuments(id);
  };

  const convertToNumber = (formattedStr: string) => {
    const number = parseFloat(formattedStr.replace(/,/g, ""));
    return number;
  };

  const RefundFunc = () => {
    if (isLoadingRefund) return;
    form
      .validateFields()
      .then((values) => {
        const data = {
          id: applicationData?.data?.id,
          data: {
            refund_amount: convertToNumber(values.refund_amount),
          },
        };
        startRefund(data);
      })
      .catch((error) => console.log("error", error));
  };

  const has24HoursPassed = (givenDate: string): boolean => {
    const hoursDifference =
      (new Date().getTime() - new Date(givenDate).getTime()) / 36e5; // 36e5 = 1000 * 60 * 60
    return hoursDifference >= 24;
  };

  const confirmRefundFunc = () => {
    const id = applicationData?.data?.id;
    if (id) {
      confirmRefund(applicationData?.data?.id);
    }
  };

  return (
    <>
      <>
        {contextHolder}
        {isLoadingResendDocuments || isLoading ? (
          <ProfileSkeleton />
        ) : (
          <div className="container">
            <div className="new-member">
              <div className="view-member">
                <div className="ViewApplication">
                  <div className="ViewApplication__content">
                    <div className="ViewApplication__sub-block ViewApplication__left-side">
                      <div className="ViewApplication__user-info-container">
                        <div className="ViewApplication__user-info">
                          <p className="ViewApplication__user-name">
                            {applicationData?.data?.first_name}{" "}
                            {applicationData?.data?.last_name}
                          </p>
                          <p className="ViewApplication__user-id-container">
                            <p className="ViewApplication__user-id-label">
                              ID#
                            </p>
                            <p className="ViewApplication__user-id">
                              {applicationData?.data?.public_id}
                            </p>
                          </p>
                          <div className="ViewApplication__partner-company-container">
                            <p className="ViewApplication__partner-company-label">
                              Partner’s Company Name
                            </p>
                            <p className="ViewApplication__partner-company-name">
                              {applicationData?.data?.partner.company_name}
                            </p>
                          </div>
                        </div>
                        <div className="ViewApplication__user-additional-info">
                          <div className="ViewApplication__user-additional-info-item">
                            <p className="ViewApplication__label">Address</p>
                            <p className="ViewApplication__additional-info">
                              {applicationData?.data?.street_address}
                            </p>
                          </div>
                          <div className="ViewApplication__info-item">
                            <div className="ViewApplication__user-info-item-wrapper">
                              <p className="ViewApplication__label">
                                Date of Birth
                              </p>
                              <p className="ViewApplication__additional-info">
                                {moment(
                                  applicationData?.data?.date_of_birth
                                ).format("MM/DD/YYYY")}
                              </p>
                            </div>
                            <div className="ViewApplication__user-city">
                              <p className="ViewApplication__label">City</p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data?.city}
                              </p>
                            </div>
                          </div>
                          <div className="ViewApplication__info-item">
                            <div className="ViewApplication__user-info-item-wrapper">
                              <p className="ViewApplication__label">State</p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data.state?.name ?? ""}
                              </p>
                            </div>
                            <div className="ViewApplication__user-city">
                              <p className="ViewApplication__label">Zip Code</p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data?.zip_code}
                              </p>
                            </div>
                          </div>
                          <div className="ViewApplication__info-item">
                            <div className="ViewApplication__user-info-item-wrapper">
                              <p className="ViewApplication__label">
                                Property Status
                              </p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data?.property_status}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="ViewApplication__product-info">
                          <p className="ViewApplication__header-label">
                            Product information
                          </p>
                          <div className="ViewApplication__info-item">
                            <div className="ViewApplication__user-info-item-wrapper">
                              <p className="ViewApplication__label">
                                Financing Amount
                              </p>
                              <p className="ViewApplication__additional-info">
                                ${" "}
                                {applicationData?.data?.financing_amount
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </p>
                            </div>
                            <div className="ViewApplication__user-city">
                              <p className="ViewApplication__label">Product</p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data?.financing_type?.name ??
                                  ""}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="ViewApplication__income-info">
                          <p className="ViewApplication__header-label">
                            Income information
                          </p>
                          <div className="ViewApplication__info-item">
                            <div className="ViewApplication__user-info-item-wrapper">
                              <p className="ViewApplication__label">
                                Annual Income
                              </p>
                              <p className="ViewApplication__additional-info">
                                ${" "}
                                {applicationData?.data?.annual_income
                                  ?.toString()
                                  ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </p>
                            </div>
                            <div className="ViewApplication__user-city">
                              <p className="ViewApplication__label">
                                Employment Status
                              </p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data?.employment_status}
                              </p>
                            </div>
                          </div>
                          <div className="ViewApplication__info-item">
                            <div className="ViewApplication__user-info-item-wrapper">
                              <p className="ViewApplication__label">
                                Company Name / Source of Income
                              </p>
                              <p className="ViewApplication__additional-info">
                                {applicationData?.data?.company_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ViewApplication__sub-block ViewApplication__right-side">
                      <div className="ViewApplication__application-status-information">
                        <p className="ViewApplication__header-label">
                          Application status information
                        </p>
                        <div className="ViewApplication__authorization-info-item ViewApplication__margin">
                          <div className="ViewApplication__status-information-item">
                            <p className="ViewApplication__status-information-item-label">
                              Application Status:
                            </p>
                            <div className="ViewApplication__status-wrapper">
                              {applicationData?.data
                                ?.additional_application_status ? (
                                <p className="ViewApplication__status-label ViewApplication__purpure-status-label">
                                  {
                                    applicationData?.data
                                      ?.additional_application_status
                                  }
                                </p>
                              ) : (
                                getStatus(
                                  applicationData?.data
                                    ?.application_status as string
                                )
                              )}
                            </div>
                          </div>
                          <div className="ViewApplication__status-information-item">
                            <p className="ViewApplication__status-information-item-label">
                              Transaction Status:
                            </p>
                            <div className="d-flex align-center">
                              {getStatus(
                                applicationData?.data
                                  ?.transaction_status as string
                              )}
                              {applicationData?.data?.transaction_status ===
                                "Failed" && (
                                <span
                                  className="ViewApplication__check-status cursor-pointer"
                                  onClick={
                                    checkStatusLoading
                                      ? () => {}
                                      : () => {
                                          checkTransactionStatus(id);
                                        }
                                  }
                                >
                                  &nbsp;Check Status
                                </span>
                              )}
                              {checkStatusLoading && (
                                <span className="d-flex">
                                  &nbsp;
                                  <Spinner size="small" color="black" />
                                </span>
                              )}
                            </div>
                          </div>
                          {(user.role.key === ADMIN ||
                            user.role.key === SUPER_ADMIN) &&
                            applicationData?.data?.bank
                              ?.fund_distribution_model && (
                              <div className="ViewApplication__status-information-item">
                                <p className="ViewApplication__status-information-item-label d-flex">
                                  <span className="mr-2">
                                    Lending Funds Distribution
                                  </span>
                                  <Tooltip title={distributionTooltipLayout}>
                                    <img src={info} alt="info" />
                                  </Tooltip>
                                </p>
                                <p className="ViewApplication__status-label">
                                  {applicationData?.data
                                    ?.fund_distribution_model === 1
                                    ? "Model 1"
                                    : "Model 2"}
                                </p>
                              </div>
                            )}
                          <div className="ViewApplication__status-information-item">
                            <p className="ViewApplication__status-information-item-label">
                              Date Created:
                            </p>
                            <p className="ViewApplication__status-label">
                              {moment(applicationData?.data?.created_at).format(
                                "LL"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="ViewApplication__auth-info">
                        <p className="ViewApplication__header-label">
                          Authorization information
                        </p>
                        <div className="ViewApplication__info-item">
                          <div className="ViewApplication__user-info-item-wrapper">
                            <p className="ViewApplication__label">
                              Phone Number
                            </p>
                            <p className="ViewApplication__additional-info">
                              {applicationData?.data?.phone}
                            </p>
                          </div>
                          <div className="ViewApplication__user-city">
                            <p className="ViewApplication__label">
                              Email Address
                            </p>
                            <p className="ViewApplication__additional-info">
                              {applicationData?.data?.email}
                            </p>
                          </div>
                        </div>
                      </div>

                      {applicationData?.data?.application_status ===
                        "Approved" &&
                        !!applicationData?.data?.secured && (
                          <TitleRecepientInfo
                            bank={applicationData?.data?.bank}
                          />
                        )}

                      {(user.role.key === ADMIN ||
                        user.role.key === SUPER_ADMIN) &&
                        applicationData?.data?.application_status ===
                          "Approved" &&
                        applicationData?.data?.fund_distribution_model ===
                          2 && (
                          <CopyLink
                            title={"Bill.com Invoice Link"}
                            link={applicationData?.data.invoice_url}
                          />
                        )}

                      {applicationData?.data?.additional_data?.genesis && (
                        <div className="ViewApplication__auth-info-concora">
                          <div className="OfferItem__head">
                            <div className="OfferItem__title ViewApplication__header-label">
                              <img
                                src={
                                  applicationData?.data?.additional_data
                                    ?.genesis.bank_logo
                                }
                                className="OfferItem__logo"
                                alt="bank logo"
                              />
                              {
                                applicationData?.data?.additional_data?.genesis
                                  .bank_name
                              }
                            </div>
                          </div>
                          <div>
                            <div className="OfferItem__row">
                              <div className="OfferItem__wrapper">
                                <p className="OfferItem__name">
                                  Approved Balance
                                </p>
                                <p className="OfferItem__value bold">
                                  $
                                  {applicationData?.data?.additional_data?.genesis.creditLine
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </p>
                              </div>
                              <div className="OfferItem__wrapper">
                                <p className="OfferItem__name">
                                  Available Balance
                                </p>
                                <p className="OfferItem__value bold">
                                  $
                                  {applicationData?.data?.additional_data?.genesis.availableCredit
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </p>
                              </div>
                              <div className="OfferItem__wrapper">
                                <p className="OfferItem__name">
                                  Transacted Amount
                                </p>
                                <p className="OfferItem__value bold">
                                  $
                                  {applicationData?.data?.additional_data?.genesis.transactionAmount
                                    ?.toString()
                                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {!applicationData?.data?.additional_data?.genesis &&
                        user.role.key !== MAIN_ACCOUNT && (
                          <div className="ViewApplication__application-status-offers">
                            <div className="d-flex align-center">
                              <p className="ViewApplication__header-label">
                                {offersLabel}
                              </p>
                              {offersChecked && (
                                <div className="ViewApplication__header-check-text">
                                  Statuses checked{" "}
                                  <img
                                    className="ml-3"
                                    src={check}
                                    alt="check"
                                  />
                                </div>
                              )}
                            </div>
                            <div>
                              {(applicationData?.data.application_status ===
                                "Pending" ||
                                (isAvailableOffers &&
                                  !offersChecked &&
                                  applicationData?.data?.application_status !==
                                    "Timed Out")) && (
                                <Button
                                  className="gray-btn-bordered w-auto"
                                  onClick={refreshStatus}
                                >
                                  {isChecking ? (
                                    <div className="d-flex align-center">
                                      <Spinner color="black" />
                                      <span style={{ marginLeft: "8px" }}>
                                        Check statuses
                                      </span>
                                    </div>
                                  ) : (
                                    "Check statuses"
                                  )}
                                </Button>
                              )}
                            </div>
                            {((isAvailableOffers &&
                              offersChecked &&
                              applicationData?.data?.application_status !==
                                "Timed Out") ||
                              ISSENDBTN) && (
                              <div className="ViewApplication__application-offers-available">
                                <div className="ViewApplication__available-send-offer-wrapper">
                                  <div className="ViewApplication__application-available-send-offer">
                                    <p className="Offers__available-for-label">
                                      {ISSENDBTN
                                        ? "Finilize Application"
                                        : "Send Offers To Applicant:"}
                                    </p>
                                    <CustomSelect
                                      className="input"
                                      placeholder="Select"
                                      value={selectedValue}
                                      options={options}
                                      onChange={(value) => handleChange(value)}
                                    />
                                  </div>
                                  <div className="Offers__available-actions">
                                    <p className="Offers__available-for-label"></p>
                                    <Button
                                      className="Offers__button"
                                      onClick={() =>
                                        handleSendOffersByType(
                                          applicationData?.data?.id as number
                                        )
                                      }
                                    >
                                      <span>Send</span>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {!applicationData?.data?.offers?.length ? (
                              <>
                                <div className="ViewApplication__no-offers-available">
                                  <div className="Offers__list-no-data">
                                    <div className="Offers__list-no-data-icon">
                                      <TrashIcon />
                                    </div>
                                    <div className="Offers__list-no-data-description">
                                      <p className="Offers__list-no-data-title">
                                        No offers available
                                      </p>
                                      <p className="Offers__list-no-data-sub-title">
                                        This applicant is not eligible for any
                                        offers
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/* app flow */}
                                {/* {user.role.key === PARTNER && (
                                  <div className="d-flex justify-end mt-12">
                                    <Button
                                      className="blue-btn-lg"
                                      onClick={() => {
                                        setOpenRerun(true);
                                      }}
                                    >
                                      Rerun application
                                    </Button>
                                  </div>
                                )} */}
                              </>
                            ) : (
                              <div className="ViewApplication__list">
                                {applicationData.data.offers.length === 1 &&
                                  applicationData.data.offers.map((offer) => (
                                    <Offer
                                      key={offer.id}
                                      offer={offer}
                                      setTrigger={setTrigger}
                                      applicationData={applicationData}
                                      status={
                                        ApprovedConcora
                                          ? "Approved-concora"
                                          : RefundedConcora
                                          ? "Refunded"
                                          : IsApproveBtn &&
                                            offer.name === "Lease-to-own"
                                          ? "ApproveBtn"
                                          : isApprovedBtn &&
                                            offer.name === "Lease-to-own"
                                          ? "ApprovedBtn"
                                          : ""
                                      }
                                    />
                                  ))}
                                {applicationData.data.offers.length > 1 &&
                                  applicationData.data.offers.map((offer) => (
                                    <OpensOfferItem
                                      key={offer.id}
                                      offer={offer}
                                      openItem={openItem}
                                      selectOption={selectOption}
                                      setOpenItem={setOpenItem}
                                      setSelectOption={setSelectOption}
                                      isWithdrawn={
                                        applicationData?.data
                                          ?.transaction_status === "Withdrawn"
                                      }
                                    />
                                  ))}

                                {/* app flow */}
                                {/* {user.role.key === PARTNER && (
                                  <div className="d-flex justify-end mt-12">
                                    <Button
                                      className="blue-btn-lg"
                                      onClick={() => {
                                        setOpenRerun(true);
                                      }}
                                    >
                                      Rerun application
                                    </Button>
                                  </div>
                                )} */}
                                {user.role.key === PARTNER &&
                                  applicationData?.data?.application_status ===
                                    "Approved" &&
                                  applicationData?.data?.bank?.key ===
                                    "genesis" &&
                                  has24HoursPassed(
                                    applicationData?.data?.status_changed_at
                                  ) && (
                                    <div className="d-flex justify-start mt-12">
                                      <Button
                                        className="btn-red-text"
                                        onClick={() => {
                                          setOpenRefund(true);
                                        }}
                                      >
                                        Request refund
                                      </Button>
                                    </div>
                                  )}
                                {(user.role.key === SUPER_ADMIN ||
                                  user.role.key === ADMIN) &&
                                  applicationData?.data?.application_status ===
                                    "Refunded" &&
                                  applicationData?.data?.bank?.key ===
                                    "genesis" && (
                                    <div className="d-flex justify-start mt-12">
                                      {applicationData?.data
                                        ?.transaction_status ===
                                      "In Progress" ? (
                                        <Button
                                          className="violet-btn"
                                          onClick={confirmRefundFunc}
                                        >
                                          {isLoadingConfirmRefund ? (
                                            <div className="d-flex align-center">
                                              <Spinner color="white" />
                                              <span
                                                style={{ marginLeft: "8px" }}
                                              >
                                                Confirm refund
                                              </span>
                                            </div>
                                          ) : (
                                            "  Confirm refund"
                                          )}
                                        </Button>
                                      ) : (
                                        <div className="ViewApplication__refund-confirm">
                                          ✔ Refund confirmed
                                        </div>
                                      )}
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>

      <ConfirmationModal
        type="alert"
        openModal={openRerun}
        setOpenModal={setOpenRerun}
        title="Rerun application"
        subtitle={
          <div>
            <div>
              Are you sure you want to rerun this application?
              <br />
              It will be closed, and a new application with the same information
              about the customer will be created
            </div>
            <div className="ViewApplication__small-text mt-8">
              <span className="ViewApplication__warning-text">*</span> Financing
              amount and product will be adjustable
            </div>
          </div>
        }
        cancelText="Discard"
        cancel={() => setOpenRerun(false)}
        confirmText="Rerun"
        confirm={cloneApp}
        closeIcon={false}
      />
      <ConfirmationModal
        type="warning"
        openModal={openRefund}
        setOpenModal={setOpenRefund}
        title="Request refund"
        subtitle={
          <div>
            <div>Please enter the refund amount</div>
            <div
              className="ViewApplication__small-text mt-8"
              style={{ width: "300px" }}
            >
              <Form form={form}>
                <Form.Item
                  className="input-wrapper"
                  name="refund_amount"
                  rules={[
                    { required: true, message: "This field is required" },
                    { validator: validateRefundAmount },
                  ]}
                >
                  <CustomInput
                    name="Refund amount"
                    className="input"
                    placeholder="1,000.00"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      currencyMaskFinancingAmount(e)
                    }
                    maxLength={16}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        }
        cancelText="Cancel"
        cancel={() => setOpenRefund(false)}
        confirmText={
          isLoadingRefund ? (
            <div className="d-flex align-center">
              <Spinner color="white" />
              <span style={{ marginLeft: "8px" }}>Request refund</span>
            </div>
          ) : (
            "Request refund"
          )
        }
        confirm={RefundFunc}
        closeIcon={false}
      />
    </>
  );
};
