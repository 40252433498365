export const GeneralItem = (props: any) => {
  const { selectedOfferData } = props;
  return (
    <div className="SelectedOffer__offer">
      <div className="SelectedOffer__topitem">
        <div className="SelectedOffer__topitem-wrap">
          <img
            src={selectedOfferData?.offer?.bank_logo}
            className="SelectedOffer__logo"
            alt="bank logo"
          />
          {selectedOfferData?.offer?.bank_name}
        </div>
        <div className="SelectedOffer__status">
          {selectedOfferData?.offer?.secured ? "Secured" : "Unsecured"}
        </div>
      </div>
      <div className="SelectedOffer__offer-item SelectedOffer__offer-item-border">
        <p className="SelectedOffer__item-label">Applicant Name</p>
        <p className="SelectedOffer__item-description">{`${selectedOfferData?.applicant?.first_name} ${selectedOfferData?.applicant?.last_name}`}</p>
      </div>
      <div className="SelectedOffer__offer-item SelectedOffer__offer-item-border">
        <p className="SelectedOffer__item-label">Loan Amount</p>
        <p className="SelectedOffer__item-description">
          ${" "}
          {selectedOfferData.offer.offer_value
            ?.toString()
            ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
        </p>
      </div>
      <div className="SelectedOffer__offer-item SelectedOffer__offer-item-border">
        <p className="SelectedOffer__item-label">Loan Type</p>
        <p className="SelectedOffer__item-description">
          {selectedOfferData?.offer?.type}
        </p>
      </div>
      {selectedOfferData.offer.bank !== "okinus" && (
        <div className="SelectedOffer__offer-item SelectedOffer__offer-item-border">
          <p className="SelectedOffer__item-label">APR</p>
          <p className="SelectedOffer__item-description">
            {selectedOfferData?.offer?.rate} %
          </p>
        </div>
      )}

      {
        <div className="SelectedOffer__offer-item SelectedOffer__offer-item-border">
          <p className="SelectedOffer__item-label">Monthly Payment</p>
          <p className="SelectedOffer__item-description">
            ${" "}
            {selectedOfferData.offer.monthly_payment
              ?.toString()
              ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </p>
        </div>
      }

      <div className="SelectedOffer__offer-item SelectedOffer__offer-item-border">
        <p className="SelectedOffer__item-label">Term</p>
        <p className="SelectedOffer__item-description">
          {selectedOfferData.offer.term}
        </p>
      </div>
    </div>
  );
};
